import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AtSign, Phone, Building, ChevronUp, ChevronDown } from "lucide-react";
import { UserProfilTagIcon, UserProfilTagIconStatus } from "../../assets/icon";
import AvatarUserIcon from "../../assets/image/AvatarProfil.png";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { set, useForm } from "react-hook-form";
const ProfileWrapper = styled.form`
  display: block;
  width: 630px;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  padding: 24px;
  font-family: "Lato", sans-serif;
  overflow-y: scroll;
  height: ${(props) => props.size - 50}px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

// const AvatarStoryWraper = styled.li`
//   display: flex;
//   flex-direction: column;
//   text-align: center;
//   position: relative;
// `;

const UserAvatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-right: 16px;
  cursor: pointer;
`;

const UserNameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #171a1f;
  margin-bottom: 4px;
`;

const UserNameRole = styled.div`
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535ce8;
  margin-bottom: 7px;
  background-color: #f1f2fd;
  text-align: center;
  padding: 5px 7px;
  border-radius: 4px;
`;

const UserId = styled.span`
  font-size: 14px;
  color: #6e7787;
  margin-left: 45px;
`;

const UserRole = styled.span`
  font-size: 14px;
  color: #000000;
  padding: 8px 8px;
  border: 1px solid #dee1e6;
  border-radius: 4px;
  display: inline-block;
  margin-top: 4px;
`;

const UserDetailsSection = styled.div`
  margin-bottom: 24px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: #323842;
`;

const DetailIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: #6e7787;
`;

const SectionTitle = styled.h3`
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #323842;
  margin-bottom: 16px;
`;

const ClientInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ClientInfoLabel = styled.span`
  font-size: 14px;
  color: #424955;
`;

const ClientInfoValue = styled.span`
  font-size: 14px;
  color: #171a1f;
  font-weight: 600;
`;

const AvatarUserId = styled.span`
  font-size: 12px;
  color: #6e7787;
  font-weight: 400;
`;

const ContainerAvatar = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  height: 35px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  font-size: 14px;
`;

const EmployeeCard = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  border: 1px solid #dee1e6;
  cursor: pointer;
`;

const EmployeeAvatar = styled.div`
  margin-right: 12px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmployeeInfo = styled.div`
  flex-grow: 1;
`;

const EmployeeName = styled.div`
  font-weight: 600;
`;

const EmployeeId = styled.div`
  font-size: 12px;
  color: #6e7787;
  text-align: center;
`;

const EmployeeUserId = styled.div`
  font-size: 12px;
  color: #6e7787;
`;

const EmployeeNameId = styled.div`
  font-size: 12px;
  color: #379ae6;
  background-color: #f1f8fd;
  padding: 4px 8px;
  border-radius: 17px;
  margin-bottom: 1px;
`;

const SaveButton = styled.button`
  width: auto;
  padding: 8px 16px;
  background-color: white;
  color: #4a7dff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  float: right;
`;

const SaveButtonUser = styled.button`
  width: auto;
  padding: 8px 16px;
  background-color: #f1f8fd;
  color: #379ae6;
  border: none;
  font-size: 14px;
  cursor: pointer;
  float: right;
  margin-top: -43px;
  border-radius: 15px;
`;

const StatusDropdown = styled.div`
  width: 60%;
  border: 1px solid #dee1e6;
  border-radius: 4px;
  background-color: #f9fafb;
  margin-bottom: 12px;
`;

const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
`;

const StatusOptions = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-top: 1px solid #dee1e6;
`;

const StatusOption = styled.label`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f0f1f3;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const DepositDiv = styled.div`
  width: 100%;
`;

const Divider = styled.hr`
  border: none;
  height: 0.5px;
  background-image: linear-gradient(to right, #e5e7eb 60%, transparent 60%);
  background-size: 6px 1px;
  background-repeat: repeat-x;
  margin: 24px 0;
`;

const options = [
  { value: 100, name: "Новый" },
  { value: 200, name: "В обработке" },
  { value: 300, name: "Внесит депозит" },
  { value: 400, name: "Закрыт" },
  { value: 500, name: "Отменен" },
];

function StatusSelect({ defaultValue, setValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(options[0]);

  useEffect(() => {
    setSelectedStatus(
      options?.find((option) => option.value === +defaultValue) || options[0]
    );
    setValue("status", defaultValue);
  }, [defaultValue]);

  return (
    <StatusDropdown>
      <StatusHeader onClick={() => setIsOpen(!isOpen)}>
        {selectedStatus.name}
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </StatusHeader>
      <StatusOptions isOpen={isOpen}>
        {options.map((option) => (
          <StatusOption key={option.value}>
            <Checkbox
              type="checkbox"
              checked={selectedStatus.value === option.value}
              onChange={() => {
                setSelectedStatus(option);
                setValue("status", option.value);
              }}
            />
            {option.name}
          </StatusOption>
        ))}
      </StatusOptions>
    </StatusDropdown>
  );
}

function StatusChange({ users, active, chat_id }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(active);

  useEffect(() => {
    setSelectedStatus(active);
  }, [active]);

  const changeManager = async () => {
    setIsLoading(true);
    try {
      const response = await request.post(`/chat/manager/change`, {
        user_id: selectedStatus?.id,
        chat_id: chat_id,
      });
      setIsLoading(false);

      if (!response?.data?.status) {
        toast.error("Ошибка при изменении обработчика");
        return;
      }

      toast.success("Обработчик успешно изменен");
    } catch (error) {
      setIsLoading(false);
      toast.error("Ошибка при изменении обработчика");
      console.error("Error deleting category:", error);
    }
  };

  return (
    <>
      <StatusDropdown>
        <StatusHeader onClick={() => setIsOpen(!isOpen)}>
          {selectedStatus?.name}
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </StatusHeader>
        <StatusOptions isOpen={isOpen}>
          {users?.map(
            (option) =>
              option.role === "manager" && (
                <StatusOption key={option.id}>
                  <Checkbox
                    type="checkbox"
                    checked={selectedStatus?.id === option?.id}
                    onChange={() => setSelectedStatus(option)}
                  />
                  {option.name}
                </StatusOption>
              )
          )}
        </StatusOptions>
      </StatusDropdown>
      <SaveButtonUser
        onClick={changeManager}
        disabled={isLoading || selectedStatus?.id === active?.id}
      >
        {isLoading ? "Загрузка..." : "Сохранить"}
      </SaveButtonUser>
    </>
  );
}

function UserProfile() {
  const { height } = useWindowSize();
  const { user } = SelectAuth();
  const [info, setInfo] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { handleSubmit, register, setValue } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await request.post(`chat/info/save`, { ...data, live_chat_id: id });
      toast.success("Данные успешно изменены");
      setLoading(false);
    } catch (error) {
      toast.error("Ошибка при изменении данных");
      console.error("Error deleting category:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await request.get("/chat/info/" + id);
        setInfo(response?.data?.data[0]);
        setValue("deposit", response?.data?.data[0]?.deposit || "0");
        // setNotifications(response.data); // Assuming API returns notifications in response.data
      } catch (err) {
        // setError("Failed to fetch ");
      }
    };

    fetchUser();
  }, []);
  useEffect(() => {
    const users = async () => {
      try {
        const response = await request.get("/users"); // Fetch users from the backend API
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
      }
    };

    users();
  }, []);

  return (
    <ProfileWrapper size={height} onSubmit={handleSubmit(onSubmit)}>
      <ClientInfoItem>
        <ClientInfoLabel>
          <ContainerAvatar>
            <div>
              <UserAvatar
                src={
                  "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                }
                alt="John Hill"
              />
            </div>
            <div>
              <UserNameBlock>
                <UserNameRole>Username</UserNameRole>
                <UserName>{info?.user?.name}</UserName>
                <UserRole>Обработчик</UserRole>
              </UserNameBlock>
            </div>
          </ContainerAvatar>
        </ClientInfoLabel>
        <AvatarUserId>Id:{info?.user?.id}</AvatarUserId>
      </ClientInfoItem>

      <Divider />
      <UserDetailsSection>
        <DetailItem>
          <DetailIcon>
            <Building size={16} />
          </DetailIcon>
          Username Telegram: <b>@{info?.user?.telegram}</b>
        </DetailItem>
        <DetailItem>
          <DetailIcon>
            <Phone size={16} />
          </DetailIcon>
          Номер телефона: <b>{info?.user?.phone_number}</b>
        </DetailItem>
        <DetailItem>
          <DetailIcon>
            <AtSign size={16} />
          </DetailIcon>
          E-mail: <b>{info?.user?.email}</b>
        </DetailItem>
      </UserDetailsSection>

      {["owner"].includes(user?.role) ? (
        <div>
          <SectionTitle>Сменить обработчика</SectionTitle>
          <StatusChange users={users} active={info?.user} chat_id={id} />
        </div>
      ) : null}

      <Divider />
      <div>
        <SectionTitle>КЛИЕНТ</SectionTitle>
        <ClientInfoItem>
          <ClientInfoLabel>ID Заявки</ClientInfoLabel>
          <ClientInfoValue>{info?.id}</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Дата регистрации</ClientInfoLabel>
          <ClientInfoValue>{info?.created_at?.slice(0, 10)}</ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Оффер</ClientInfoLabel>
          <ClientInfoValue>
            {(info?.chat?.info?.first_name || "Пользователь") +
              " " +
              (info?.chat?.info?.last_name || "")}
          </ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Username</ClientInfoLabel>
          <ClientInfoValue>
            <a
              href={`https://t.me/${info?.chat?.info?.username}`}
              target="_blank"
              rel="noreferrer"
            >
              @{info?.chat?.info?.username}
            </a>
          </ClientInfoValue>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Статус</ClientInfoLabel>
          <StatusSelect setValue={setValue} defaultValue={info.status} />
        </ClientInfoItem>
        <ClientInfoItem>
          <DepositDiv>
            <SectionTitle>Депозит</SectionTitle>
            <Input type="text" {...register("deposit", { required: true })} />
          </DepositDiv>
        </ClientInfoItem>
        <ClientInfoItem>
          <ClientInfoLabel>Площадка</ClientInfoLabel>
          <ClientInfoValue>{info?.link?.platform}</ClientInfoValue>
        </ClientInfoItem>
      </div>
      <Divider />
      <EmployeeCard>
        <EmployeeAvatar>
          <UserProfilTagIcon />
        </EmployeeAvatar>
        <EmployeeInfo>
          <EmployeeName>Обработчик</EmployeeName>
          <EmployeeUserId>{info?.user?.name}</EmployeeUserId>
        </EmployeeInfo>
        <div>
          <EmployeeNameId>ID: {info?.user?.id}</EmployeeNameId>
          <EmployeeId>@{info?.user?.username}</EmployeeId>
        </div>
      </EmployeeCard>

      <EmployeeCard>
        <EmployeeAvatar>
          <UserProfilTagIconStatus />
        </EmployeeAvatar>
        <EmployeeInfo>
          <EmployeeName>Баер</EmployeeName>
          <EmployeeUserId>{info?.buyer?.name}</EmployeeUserId>
        </EmployeeInfo>
        <div>
          <EmployeeNameId>ID: {info?.buyer?.id}</EmployeeNameId>
          <EmployeeId>{info?.buyer?.username}</EmployeeId>
        </div>
      </EmployeeCard>
      {["owner"].includes(user?.role) ? (
        <SaveButton disabled={loading}>
          {loading ? "Загрузка..." : "Сохранить"}
        </SaveButton>
      ) : null}
    </ProfileWrapper>
  );
}

export default UserProfile;
